import mixpanel from "mixpanel-browser";
import mainStore from "store/store";

const userId = () => {
  const { uniqueUserId } = mainStore();
  return uniqueUserId;
};

export const trackPageViewEvent = (data) => {
  mixpanel.track("PageView", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    is_landing: true,
    page_url: window.location.href,
    page_type: data.page_type,
  });
};

export const trackAddToCartEvent = () => {
  mixpanel.track("Add to Cart", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    page_url: window.location.href,
  });
};

export const trackCheckoutStartedEvent = () => {
  mixpanel.track("Checkout Started", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    page_url: window.location.href,
  });
};

export const trackUpsellAccepted = (sku) => {
  mixpanel.track("Upsell Accepted", {
    distinct_id: userId,
    time: Math.floor(Date.now() / 1000),
    page_url: window.location.href,
    sku: sku,
  });
};

export const trackPurchaseEvent = (data) => {
  console.log("trackPurchaseEvent ~ data:", data);
  const totalAmount = data.details.items.reduce((acc, item) => {
    return acc + item.price;
  }, 0);

  const customer = data.details.customer;
  mixpanel.track("Purchase", {
    distinct_id: data.uniqueUserId,
    time: Math.floor(Date.now() / 1000),
    user_email: data.details.customer.email,
    total_amount: totalAmount,
    payment_method: data.gateway || "nmi",
    currency: data.details.currency || "USD",
    country: data.details.customer.country || "Unknown",
    city: data.details.customer.city || "Unknown",
    cart_items: JSON.stringify(data.details.items),
    shipping_method: "standard",
    current_url: data.eventDetails?.url || "Unknown",
    device: data.eventDetails.device || "Unknown",
    utm_campaign: data.eventDetails.utm_campaign || "none",
    utm_medium: data.eventDetails.utm_medium || "none",
    utm_source: data.eventDetails.utm_source || "none",
  });
};
