import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const onRoute = (route) => {
    navigate(route);
  };

  return (
    <>
      <div class="new-footer-sec">
        <div class="footer-con w-container">
          <div class="div-block-367">
            <div class="div-block-374">
              <div class="text-block-88">
                Copyright 2023 Nuzzle | All Rights Reserved.
                <br />
              </div>
              <img
                src="images/dy.png_1dy.png.webp"
                loading="lazy"
                alt=""
                class="image-74"
              />
            </div>
            <div class="div-block-368">
              <div class="text-block-89">
                <a
                  aria-current="page"
                  class="link-6 w--current"
                  onClick={() => onRoute("/about-us")}
                >
                  About Us
                </a>
                 | 
                <a class="link-2" onClick={() => onRoute("/terms-conditions")}>
                  Terms &amp; Conditions{" "}
                </a>
                |
                <a class="link-3" onClick={() => onRoute("/privacy-policy")}>
                  Privacy Policy
                </a>
                |
                <a class="link-7" onClick={() => onRoute("/return-policy")}>
                  Returns
                </a>
                |
                <a class="link-4" onClick={() => onRoute("/contact-us")}>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
