import Header from "./header";
import Footer from "./footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div class="section-5">
        <div class="container-12 w-container">
          <img src="../images/logo_v02.png" loading="lazy" width="119" alt="" />
        </div>
      </div>
      <div class="section-6">
        <div class="w-container">
          <h1 class="heading-30">Privacy Policy</h1>
          <p class="paragraph-22">
            At Nuzzle, we take your privacy seriously. Please read this Privacy
            Policy to learn how we treat your personal data. By using or
            accessing our Services in any manner, you acknowledge that you
            accept the practices and policies outlined below, and you hereby
            consent that we will collect, use and share your information as
            described in this Privacy Policy.Remember that your use of
            Nuzzle&#x27;s Services is at all time subject to our Terms of Use,
            which incorporates this Privacy Policy. Any terms we use in this
            Policy without defining them have the definitions given to the min
            the Terms of Use.
            <br />
            <br />‍
            <strong>
              What this Privacy Policy Covers
              <br />‍<br />‍
            </strong>
            This Privacy Policy covers how we treat Personal Data that we gather
            when you access or use our Services. “Personal Data” means any
            information that identifies or relates to a particular individual
            and also includes information referred to as “personally
            identifiable information” or“personal information” under applicable
            data privacy laws, rules or regulations. This Privacy Policy does
            not cover the practices of companies we don’t own or control or
            people we don’t manage.
            <br />
            <br />‍
            <strong>
              Personal Data
              <br />
              <br />
              Categories of Personal Data We Collect
              <br />
              <br />‍
            </strong>
            This chart details the categories of Personal Data that we collect
            and have collected over the past 12 months:
            <br />
            <br />‍
            <strong>
              Category of Personal Data: Profile or Contact Data
              <br />
              <br />‍
            </strong>
            Examples of Personal Data We Collect:
            <br />‍<br />
            ·    First and last name
            <br />
            ·    Email
            <br />
            ·    Phone number <br />‍<br />
            Categories of Third Parties With Whom We Share this Personal Data:
            <br />‍<br />
            ·    Service Providers
            <br />
            ·    Advertising Partners
            <br />
            ·    Analytics Partners
            <br />
            ·    Business Partners
            <br />
            ·    Parties You Authorize, Access or Authenticate
            <br />
            <br />‍
            <strong>
              Category of Personal Data: Payment Data
              <br />
              <br />‍
            </strong>
            Examples of Personal Data We Collect:
            <br />‍<br />
            ·    Payment card type
            <br />
            ·    Last 4 digits of payment card
            <br />
            ·    Billing address, phone number, and email <br />
            <br />
            Categories of Third Parties With Whom We Share this Personal Data:
            <br />‍<br />
            ·    Service Providers (specifically our payment gateway partner
            Authorize.net and our merchant processor Nuvei Inc.)
            <br />
            <br />‍
            <strong>
              Category of Personal Data: Commercial Data
              <br />
              <br />‍
            </strong>
            Examples of Personal Data We Collect:
            <br />
            <br />
            ·    Purchase history
            <br />
            ·    Consumer profiles
            <br />
            <br />
            Categories of Third Parties With Whom We Share this Personal Data:
            <br />
            <br />
            ·    Service Providers
            <br />
            ·    Advertising Partners
            <br />
            ·    Analytics Partners
            <br />
            ·    Business Partners
            <br />
            ·    Parties You Authorize, Access or Authenticate
            <br />
            <br />‍
            <strong>
              Category of Personal Data: Device/IP Data
              <br />
              <br />‍
            </strong>
            Examples of Personal Data We Collect:
            <br />
            ·    IP address
            <br />
            ·    Device ID
            <br />
            ·    Domain server
            <br />
            ·    Type of device/operating system/browser used to access the
            Services
            <br />
            <br />
            Categories of Third Parties With Whom We Share this Personal Data:
            <br />‍<br />
            ·    Service Providers
            <br />
            ·    Advertising Partners
            <br />
            ·    Analytics Partners
            <br />
            ·    Business Partners
            <br />
            ·    Parties You Authorize, Access or Authenticate
            <br />
            <br />‍
            <strong>
              Category of Personal Data: Web Analytics
              <br />
              <br />‍
            </strong>
            Examples of Personal Data We Collect:
            <br />
            <br />
            ·    Web page interactions
            <br />
            ·    Referring webpage/source through which you accessed the
            Services
            <br />
            ·    Non-identifiable request IDs
            <br />
            ·    Statistics associated with the interaction between device or
            browser and the Services <br />
            <br />
            Categories of Third Parties With Whom We Share this Personal Data:
            <br />
            ·    Service Providers
            <br />
            ·    Advertising Partners
            <br />
            ·    Analytics Partners
            <br />
            ·    Business Partners
            <br />
            ·    Parties You Authorize, Access or Authenticate
            <br />
            <br />‍
            <strong>
              Category of Personal Data: Consumer Demographic Data
              <br />
              <br />‍
            </strong>
            Examples of Personal Data We Collect:
            <br />
            <br />
            ·    Age / date of birth
            <br />
            ·    Zip code
            <br />
            ·    Gender
            <br />
            ·    Race
            <br />
            ·    Ethnicity
            <br />
            <br />
            Categories of Third Parties With Whom We Share this Personal Data:
            <br />
            <br />
            ·    Service Providers
            <br />
            ·    Advertising Partners (only zip code)
            <br />
            ·    Analytics Partners (only zip code)
            <br />
            ·    Business Partners (only zip code)
            <br />
            ·    Parties You Authorize, Access or Authenticate
            <br />
            <br />‍
            <strong>
              Categories of Sources of Personal Data
              <br />
              <br />‍
            </strong>
            We collect Personal Data about you from the following categories of
            sources:
            <br />
            <br />
            ·    You
            <br />
            ·    When you provide such information directly to us.
            <br />‍<br />
            When you use our Services.
            <br />
            When you voluntarily provide information in free-form text boxes
            through the Services or through responses to surveys or
            questionnaires.
            <br />‍<br />
            o   When you send us an email or otherwise contact us.
            <br />‍<br />
            ·    When you use the Services and such information is collected
            automatically.
            <br />‍<br />
            o  Through Cookies (defined in the “Tracking Tools, Advertising and
            Opt-Out” section below).
            <br />‍<br />
            o   If you use a location-enabled browser, we may receive
            information about your location.
            <br />
            <br />
            ·    Third Parties
            <br />
            ·    Vendors
            <br />
            <br />
            We may use analytics providers to analyze how you interact and
            engage with the Services, or third parties may help us provide you
            with customer support.
            <br />
            <br />
            o   We may use vendors to obtain information to generate leads and
            create user profiles.
            <br />
            <br />
            ·    Advertising Partners
            <br />
            <br />
            o   We receive information about you from some of our vendors who
            assist us with marketing or promotional services related to how you
            interact with our websites, applications, Products,
            Services,advertisements or communications
            <br />
            <br />‍
            <strong>
              Our Commercial or Business Purposes for Collecting Personal Data
              <br />
              <br />‍
            </strong>
            Providing, Customizing and Improving the Services
            <br />‍<br />
            o   Processing orders or other transactions;billing.
            <br />
            o   Providing you with the Products, Services or information you
            request.
            <br />
            o   Meeting or fulfilling the reason you provided the information to
            us.
            <br />
            o   Providing support and assistance for the Products and Services.
            <br />
            o   Improving the Products and Services, including testing,
            research, internal analytics and product development.
            <br />
            o   Personalizing the Services, website content and communications
            based on your preferences.
            <br />
            o   Doing fraud protection, security and debugging.
            <br />
            o   Carrying out other business purposes stated when collecting your
            Personal Data or as otherwise set forth in applicable data privacy
            laws, such as the California Consumer Privacy Act (the “CCPA”).
            <br />
            <br />
            ·    Marketing the Services
            <br />
            <br />
            o   Marketing and selling the Products.
            <br />
            o   Showing you advertisements, including interest-based or online
            behavioral advertising.
            <br />
            <br />
            ·    Corresponding with You
            <br />
            <br />
            o   Responding to correspondence that we receive from you,
            contacting you when necessary or requested, and sending you
            information about Nuzzle or the Products and Services.
            <br />
            o   Sending emails and other communications according to your
            preferences or that display content that we think will interest you.
            <br />‍<br />
            ·    Meeting Legal Requirements and Enforcing Legal Terms
            <br />‍<br />
            o   Fulfilling our legal obligations under applicable law,
            regulation, court order or other legal process, such as preventing,
            detecting and investigating security incidents and potentially
            illegal or prohibited activities.
            <br />
            o   Protecting the rights, property or safety of you, Nuzzle or
            another party.
            <br />
            o   Enforcing any agreements with you.
            <br />
            o   Responding to claims that any posting or other content violates
            third-party rights.
            <br />
            o   Resolving disputes.
            <br />
            <br />
            We will not collect additional categories of Personal Data or use
            the Personal Data we collected for materially different, unrelated
            or incompatible purposes without providing you notice.
            <br />
            <br />‍
            <strong>
              How We Share Your Personal Data
              <br />
              <br />‍
            </strong>
            We disclose your Personal Data to the categories of service
            providers and other parties listed in this section. Depending on
            state laws that may be applicable to you, some of these disclosures
            may constitute a“sale” of your Personal Data. For more information,
            please refer to the state-specific sections below.
            <br />‍<br />
            ·    Service Providers. These parties help us provide the Services
            or perform business functions on our behalf. They include:
            <br />‍<br />
            o   Hosting, technology and communication providers.
            <br />
            o   Security and fraud prevention vendors.
            <br />
            o   Support and customer service vendors.
            <br />
            o   Product fulfillment and delivery providers.
            <br />‍<br />
            ·    Payment processors.
            <br />‍<br />
            Our payment processing partner (specifically our payment gateway
            partner Authorize.net and our merchant processor NuveiInc.) collects
            your voluntarily-provided payment card information necessary to
            process your payment.
            <br />‍<br />
            o   Please see [Stripe’s][Square’s][Braintree’s]terms of service and
            privacy policy for information on its use and storage of your
            Personal Data.
            <br />‍<br />
            ·    Advertising Partners. These parties help us market our services
            and provide you with other offers that may be of interest to you.
            They include:
            <br />‍<br />
            o   Ad networks.
            <br />
            o   Data brokers.
            <br />
            o   Marketing providers.
            <br />‍<br />
            ·    Analytics Partners. These parties provide analytics on web
            traffic or usage of the Services. They include:
            <br />‍<br />
            o   Companies that track how users found or were referred to the
            Services.
            <br />
            o   Companies that track how users interact with the Services.
            <br />‍<br />
            ·    Business Partners. These parties partner with us in offering
            various services. They include:
            <br />‍<br />
            o   Businesses that you have a relationship with.
            <br />
            o   Companies that we partner with to offer joint promotional offers
            or opportunities.
            <br />‍<br />
            ·    Parties You Authorize, Access or Authenticate
            <br />‍<br />
            o   Third parties you access through the services.
            <br />
            o   Social media services.
            <br />
            o   Other users.
            <br />
            <br />‍
            <strong>
              Legal Obligations
              <br />
              <br />‍
            </strong>
            We may share any Personal Data that we collect with third parties in
            conjunction with any of the activities set forth under “Meeting
            Legal Requirements and Enforcing Legal Terms” in the “Our Commercial
            or Business Purposes for Collecting Personal Data” section above.
            <br />
            <br />‍
            <strong>
              Business Transfers
              <br />
              <br />‍
            </strong>
            All of your Personal Data that we collect may be transferred to a
            third party if we undergo a merger, acquisition, bankruptcy or other
            transaction in which that third party assumes control of our
            business (in whole or in part). Should one of these events occur, we
            will make reasonable efforts to notify you before your information
            becomes subject to different privacy and security policies and
            practices.
            <br />
            <br />‍
            <strong>
              Data that is Not Personal Data
              <br />
              <br />‍
            </strong>
            We may create aggregated, de-identified or anonymized data from the
            Personal Data we collect, including by removing information that
            makes the data personally identifiable to a particular user. We may
            use such aggregated, de-identified or anonymized data and share it
            with third parties for our lawful business purposes, including to
            analyze, build and improve the Products and Services and promote our
            business, provided that we will not share such data in a manner that
            could identify you.
            <br />
            <br />‍
            <strong>
              <em>
                Tracking Tools, Advertising and Opt-Out
                <br />
                <br />‍
              </em>
            </strong>
            The Services use cookies and similar technologies such as pixel
            tags, web beacons, clear GIFs and JavaScript (collectively,
            “Cookies”) to enable our servers to recognize your web browser, tell
            us how and when you visit and use our Services, analyze trends,
            learn about our user base and operate and improve our Services.
            Cookies are small pieces of data– usually text files – placed on
            your computer, tablet, phone or similar device when you use that
            device to access our Services. We may also supplement the
            information we collect from you with information received from third
            parties, including third parties that have placed their own Cookies
            on your device(s). Please note that because of our use of Cookies,
            the Services do not support “Do Not Track”requests sent from a
            browser at this time.
            <br />
            <br />
            We use the following types of Cookies:
            <br />‍<br />
            ·        <span class="text-span-97">Essential Cookies.</span>
            Essential Cookies are required for providing you with features or
            services that you have requested. For example, certain Cookies
            enable you to log into secure areas of our Services. Disabling these
            Cookies may make certain features and services unavailable.
            <br />
            ·        <span class="text-span-98">Functional Cookies.</span>
            Functional Cookies are used to record your choices and settings
            regarding our Services, maintain your preferences over time and
            recognize you when you return to our Services.These Cookies help us
            to personalize our content for you, greet you by name and remember
            your preferences (for example, your choice of language or region).
            <br />
            ·        
            <span class="text-span-99">Performance/Analytical Cookies.</span>
            Performance/Analytical Cookies allow us to understand how visitors
            use our Services. They do this by collecting information about the
            number of visitors to the Services, what pages visitors view on our
            Services and how long visitors are viewing pages on the Services.
            Performance/Analytical Cookies also help us measure the performance
            of our advertising campaigns in order to help us improve our
            campaigns and the Services’ content for those who engage with our
            advertising. For example, Google Inc. (“Google”) uses cookies in
            connection with its Google Analytics services. Google’s ability to
            use and share information collected by Google Analytics about your
            visits to the Services is subject to the Google Analytics Terms of
            Use and the Google Privacy Policy. You have the option to opt-out of
            Google’s use of Cookies by visiting the Google advertising opt-out
            page at www.google.com/privacy_ads.html or the Google Analytics
            Opt-out Browser Add-on at https://tools.google.com/dlpage/gaoptout/.
            <br />
            ·        
            <span class="text-span-100">Retargeting/Advertising Cookies.</span>
            Retargeting/Advertising Cookies collect data about your online
            activity and identify your interests so that we can provide
            advertising that we believe is relevant to you. For more information
            about this, please see the section below titled “Information about
            Interest-Based Advertisements.” We currently use Klaviyo for email,
            Google for analytics and advertising, Facebook for advertising,
            Pinterest for advertising, Reddit for advertising and Just Uno for
            notifications. Each of the foregoing perform site measurement
            services via pixels, tags or cookies.
            <br />
            <br />
            You can decide whether or not to accept Cookies through your
            internet browser’s settings. Most browsers have an option for
            turning off the Cookie feature, which will prevent your browser from
            accepting new Cookies, as well as (depending on the sophistication
            of your browser software) allow you to decide on acceptance of each
            new Cookie in a variety of ways. You can also delete all Cookies
            that are already on your device. If you do this, however,you may
            have to manually adjust some preferences every time you visit our
            website and some of the Services and functionalities may not work.
            <br />
            <br />
            To explore what Cookie settings are available to you, look in the
            “preferences” or “options” section of your browser’s menu. To find
            out more information about Cookies, including information about how
            to manage and delete Cookies, please
            visit http://www.allaboutcookies.org/.
            <br />
            <br />‍
            <strong>
              Information about Interest-Based Advertisements:
              <br />
              <br />‍
            </strong>
            We may serve advertisements, and also allow third-party ad networks,
            including third-party ad servers, ad agencies, ad technology vendors
            and research firms, to serve advertisements through the Services.
            These advertisements may be targeted to users who fit certain
            general profile categories or display certain preferences or
            behaviors (“Interest-Based Ads”).Information for Interest-Based Ads
            (including Personal Data) may be provided to us by you, or derived
            from the usage patterns of particular users on the Services and/or
            services of third parties. Such information may be gathered through
            tracking users’ activities across time and unaffiliated
            properties,including when you leave the Services. To accomplish
            this, we or our service providers may deliver Cookies, including a
            file (known as a “web beacon”) from an ad network to you through the
            Services. Web beacons allow ad networks to provide anonymized,
            aggregated auditing, research and reporting for us and for
            advertisers. Web beacons also enable ad networks to serve targeted
            advertisements to you when you visit other websites. Web beacons
            allow ad networks to view, edit or set their own Cookies on your
            browser, just as if you had requested a web page from their site.
            <br />
            <br />
            We comply with the Digital Advertising Alliance (“DAA”)
            Self-Regulatory Principles for Online Behavioral Advertising.
            Through the DAA and Network Advertising Initiative (“NAI”), several
            media and marketing associations have developed an industry
            self-regulatory program to give consumers a better understanding of,
            and greater control over, ads that are customized based a consumer’s
            online behavior across different websites and properties. To make
            choices about Interest-Based Ads from participating third parties,
            including to opt-out of receiving behaviorally targeted
            advertisements from participating organizations, please visit the
            DAA’s or NAI’s consumer opt-out pages, which are located at 
            <span class="text-span-101">
              http://www.networkadvertising.org/choices/
            </span>
             or <span class="text-span-102">www.aboutads.info/choices</span>.
            <br />
            <br />‍
            <strong>
              Data that is Not Personal Data
              <br />
              <br />‍
            </strong>
            We seek to protect your Personal Data from unauthorized access,use
            and disclosure using appropriate physical, technical, organizational
            and administrative security measures based on the type of Personal
            Data and how we are processing that data. You should also help
            protect your data by appropriately limiting access to your computer
            or device and browser. Although we work to protect the security of
            any data that we hold in our records, please be aware that no method
            of transmitting data over the internet or storing data is completely
            secure.
            <br />
            <br />
            We retain Personal Data about you for as long as necessary to
            provide you with our Services. In some cases we retain Personal Data
            for longer, if doing so is necessary to comply with our legal
            obligations, resolve disputes or collect fees owed, or is otherwise
            permitted or required by applicable law, rule or regulation. We may
            further retain information in an anonymous or aggregated form where
            that information would not identify you personally.
            <br />
            <br />‍
            <strong>
              Personal Data of Children
              <br />
              <br />‍
            </strong>
            As noted in the Terms of Use, we do not knowingly collect or solicit
            Personal Data about children under 13 years of age; if you are a
            child under the age of 13, please do not attempt to register for or
            otherwise use the Services or send us any Personal Data. If we learn
            we have collected Personal Data from a child under 13 years of age,
            we will delete that information as quickly as possible. If you
            believe that a child under 13 years of age may have provided
            Personal Data to us, please contact us at 
            <a href="mailto:support@getnuzzle.com">support@getnuzzle.com.</a>
            <br />
            <br />‍
            <strong>
              California Resident Rights
              <br />
              <br />‍
            </strong>
            If you are a California resident, you have the rights set for thin
            this section. Please see the “Exercising Your Rights” section below
            for instructions regarding how to exercise these rights. Please note
            that we may process Personal Data of our customers’ end users or
            employees in connection with our provision of certain services to
            our customers. If we are processing your Personal Data as a service
            provider, you should contact the entity that collected your Personal
            Data in the first instance to address your rights with respect to
            such data.If there are any conflicts between this section and any
            other provision of this Privacy Policy and you are a California
            resident, the portion that is more protective of Personal Data shall
            control to the extent of such conflict.If you have any questions
            about this section or whether any of the following rights apply to
            you, please contact us at 
            <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>.
            <br />
            <br />‍
            <strong>
              Access
              <br />
              <br />‍
            </strong>
            You have the right to request certain information about our
            collection and use of your Personal Data over the past 12 months. In
            response,we will provide you with the following information:
            <br />‍<br />
            ·        The categories of Personal Data that we have collected
            about you.
            <br />
            ·        The categories of sources from which that Personal Data was
            collected.
            <br />
            ·        The business or commercial purpose for collecting or
            selling your Personal Data.
            <br />
            ·        The categories of third parties with whom we have shared
            your Personal Data.
            <br />
            ·        The specific pieces of Personal Data that we have collected
            about you.
            <br />
            <br />
            If we have disclosed your Personal Data to any third parties fora
            business purpose over the past 12 months, we will identify the
            categories of Personal Data shared with each category of third party
            recipient. If we have sold your Personal Data over the past 12
            months, we will identify the categories of Personal Data sold to
            each category of third party recipient.
            <br />
            <br />‍
            <strong>
              Deletion
              <br />
              <br />‍
            </strong>
            You have the right to request that we delete the Personal Data that
            we have collected about you. Under the CCPA, this right is subject
            to certain exceptions: for example, we may need to retain your
            Personal Data to provide you with the Services or complete a
            transaction or other action you have requested. If your deletion
            request is subject to one of these exceptions,we may deny your
            deletion request.
            <br />
            <br />‍
            <strong>
              Exercising Your Rights
              <br />
              <br />‍
            </strong>
            To exercise the rights described above, you or your Authorized Agent
            (defined below) must send us a request that (1) provides sufficient
            information to allow us to verify that you are the person about whom
            we have collected Personal Data (specifically, your name, email
            address and, if applicable, order number), and (2) describes your
            request in sufficient detail to allow us to understand, evaluate and
            respond to it. Each request that meets both of these criteria will
            be considered a “Valid Request.” We may not respond to requests that
            do not meet these criteria. We will only use Personal Data provided
            in a Valid Request to verify your identity and complete your
            request.
            <br />
            <br />
            We will work to respond to your Valid Request within 45 days of
            receipt. We will not charge you a fee for making a Valid Request
            unless your Valid Request(s) is excessive, repetitive or manifestly
            unfounded. If we determine that your Valid Request warrants a fee,
            we will notify you of the fee and explain that decision before
            completing your request.
            <br />
            <br />
            You may submit a Valid Request using the following methods:
            <br />‍<br />
            ·        Email us at: 
            <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>
            <br />
            <br />
            You may also authorize an agent (an “Authorized Agent”) to exercise
            your rights on your behalf. To do this, you must provide your
            Authorized Agent with written permission to exercise your rights on
            your behalf, and we may request a copy of this written permission
            from your Authorized Agent when they make a request on your behalf.
            <br />
            <br />‍
            <strong>
              Personal Data Sales Opt-Out and Opt-In
              <br />‍<br />‍
            </strong>
            We will not sell your Personal Data, and have not done so over the
            last 12 months. To our knowledge, we do not sell the Personal Data
            of minors under 13 years of age.
            <br />
            <br />‍
            <strong>
              We Will Not Discriminate Against You for Exercising Your Rights
              Under the CCPA
              <br />‍<br />‍
            </strong>
            We will not discriminate against you for exercising your rights
            under the CCPA. We will not deny you our goods or services, charge
            you different prices or rates, or provide you a lower quality of
            goods and services if you exercise your rights under the CCPA.
            However, we may offer different tiers of our Services as allowed by
            applicable data privacy laws (including the CCPA) with varying
            prices, rates or levels of quality of the goods or services you
            receive related to the value of Personal Data that we receive from
            you.
            <strong>
              <br />
              <br />
              Other State Law Privacy Rights California Resident Rights
              <br />‍<br />
            </strong>
            Under California Civil Code Sections 1798.83-1798.84, California
            residents are entitled to contact us to prevent disclosure of
            Personal Data to third parties for such third parties’direct
            marketing purposes; in order to submit such a request, please
            contact us at 
            <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>.
            <br />
            <br />‍<strong>Nevada Resident Rights</strong>
            <br />‍<br />
            If you are a resident of Nevada, you have the right to opt-out of
            the sale of certain Personal Data to third parties who intend to
            license or sell that Personal Data. You can exercise this right by
            contacting us at 
            <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>
             withthe subject line “Nevada Do Not Sell Request” and providing us
            with your name and the email address.
            <br />
            <br />‍
            <strong>
              Changes to this Privacy Policy
              <br />‍<br />‍
            </strong>
            We’re constantly trying to improve our Services, so we may need to
            change this Privacy Policy from time to time, but we will alert you
            to any such changes by placing a notice on the Nuzzle website, by
            sending you an email and/or by some other means. Please note that if
            you’ve opted not to receive legal notice emails from us (or you
            haven’t provided us with your email address), those legal notices
            will still govern your use of the Services, and you are still
            responsible for reading and understanding them. If you use the
            Services after any changes to the Privacy Policy have been posted,
            that means you agree to all of the changes. Use of information we
            collect is subject to the Privacy Policy in effect at the time such
            information is collected.
            <br />
            <br />‍
            <strong>
              Contact Information:
              <br />
              <br />‍
            </strong>
            If you have any questions or comments about this Privacy Policy, the
            ways in which we collect and use your Personal Data or your choices
            and rights regarding such collection and use, please do not hesitate
            to contact us at: 
            <a href="mailto:support@getnuzzle.com">support@getnuzzle.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
