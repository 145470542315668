import React from "react";
import moment from "moment";

function ProductDetails() {
  const addBusinessDays = (startDate, daysToAdd) => {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.add(1, "days");
      if (currentDate.isoWeekday() <= 5) {
        addedDays++;
      }
    }
    return currentDate;
  };

  const deliverDate = addBusinessDays(moment(), 1).format("dddd, MMM Do");

  return (
    <div>
      <div class="top-block-ss-div topnew">
        <div class="top-div-badge-new ff1-d rem1">
          <div class="re-block we2 rem2">
            <div class="ccr-div rem">
              <img src="../images/Fly.svg" loading="lazy" alt="" class="tu1" />
            </div>
            <div class="top-b-txt">Fast Shipping</div>
          </div>
          <div class="re-block we1 wo1 rem2">
            <div class="ccr-div rem">
              <img src="../images/Days.svg" loading="lazy" alt="" class="tu2" />
            </div>
            <div class="top-b-txt">90-Day Returns</div>
          </div>
          <div class="re-block we3 wo1 rem2">
            <div class="ccr-div rem">
              <img
                src="../images/Return.svg"
                loading="lazy"
                alt=""
                class="tu3"
              />
            </div>
            <div class="top-b-txt">Easy Exchanges</div>
          </div>
        </div>
        <div class="faq-wrapper">
          <div className="ProductSection">
            <div className="Option">
              <input className="Toggle" id="Accordion_1" type="checkbox" />
              <label className="Title" for="Accordion_1">
                <img src="img/product_icon.svg" alt="Icon" />
                Product Details
              </label>
              <div className="Content Product">
                <div className="Details">
                  <div className="Head">
                    <h4>Standard</h4>
                    <span>18" x 26"</span>
                    <ul>
                      <li>100% cotton cover</li>
                      <li>polyester fabric lining </li>
                      <li>Nanocoil microfiber</li>
                    </ul>
                  </div>
                </div>
                <div className="Details">
                  <div className="Head">
                    <h4>King</h4>
                    <span>18" x 34"</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="Option">
              <input className="Toggle" id="Accordion_2" type="checkbox" />
              <label className="Title" for="Accordion_2">
                <img src="img/shipping_icon.svg" alt="Icon" />
                Shipping Details
              </label>
              <div className="Content Shipping">
                <h4>
                  <b>Delivery:</b> Ships by {deliverDate} from Order within{" "}
                  <span>3 hrs 6 mins</span>
                </h4>
                <h4>
                  <b>Courier Company:</b>{" "}
                  <img src="img/courier.svg" alt="Courier" /> UNITED STATES
                  POSTAL SERVICE‍
                </h4>
                <p>
                  All orders are shipped with express service and include a
                  tracking number.
                </p>
                <p>
                  We ensure that your package will reach you safely and on time.
                  If there are any problems during transit, we will work to
                  solve the problems and provide the best solutions.
                </p>
              </div>
            </div>
            <div className="Option">
              <input className="Toggle" id="Accordion_3" type="checkbox" />
              <label className="Title" for="Accordion_3">
                <img src="img/return_icon.svg" alt="Icon" />
                Nuzzle's Guarantee
              </label>
              <div className="Content Refund">
                <ul>
                  <li>
                    <img src="img/free_returns.svg" alt="Free" />{" "}
                    <span>Free returns within 90 days</span>
                  </li>
                  <li>
                    <img src="img/check.svg" alt="Check" />{" "}
                    <p>
                      Any item(s) are eligible for a full refund within 90 days
                      of purchase.
                    </p>
                  </li>
                  <li>
                    <img src="img/check.svg" alt="Check" />{" "}
                    <p>
                      Email us at support@getnuzzle.com for quality US-based
                      support.
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <img src="img/shop_verified.svg" alt="Shop" />{" "}
                    <span>Shop confidently with our secure systems</span>
                  </li>
                  <li>
                    <img src="img/check.svg" alt="Check" />{" "}
                    <p>Card information is secure and uncompromised</p>
                  </li>
                  <li>
                    <img src="img/check.svg" alt="Check" />{" "}
                    <p>We keep your personal data private, safe, and secure</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="faq-wrapper">
          <section class="faq-f66">
            <a
              data-w-id="af93db0f-648c-042c-1dce-f7169bfce054"
              href="#"
              class="question-wrapper-f66 w-inline-block"
            >
              <div class="question-f66">
                <div class="lef-faq-div">
                  <div class="ico-div-faq">
                    <img src="../images/tt1.svg" loading="lazy" alt="" />
                  </div>
                  <div class="text-question-f66">
                    <strong>Product Details</strong>
                    <br />
                  </div>
                </div>
                <div class="icon-wrapper-f66">
                  <img
                    src="../images/arrow_black.svg"
                    loading="lazy"
                    width="14"
                    alt=""
                    class="arrow-black-f66"
                  />
                  <img
                    src="../images/arrow_black.svg"
                    loading="lazy"
                    width="14"
                    style={{ display: "none" }}
                    alt=""
                    class="arrow-blue-f66"
                  />
                </div>
              </div>
              <div class="div-block-879 answer-f66 re1">
                <div>
                  <div class="st-text">Standard</div>
                  <div class="me-size-text">18&quot; x 26&quot;</div>
                  <ul role="list" class="list-4">
                    <li>100% cotton cover</li>
                    <li>polyester fabric lining</li>
                    <li>Nanocoil microfiber</li>
                  </ul>
                </div>
                <div class="div-block-880">
                  <div class="st-text">King</div>
                  <div class="me-size-text">18&quot; x 34&quot;</div>
                </div>
              </div>
            </a>
            <a
              data-w-id="af93db0f-648c-042c-1dce-f7169bfce05f"
              href="#"
              class="question-wrapper-f66 w-inline-block"
            >
              <div class="question-f66 nobor">
                <div class="lef-faq-div">
                  <div class="ico-div-faq dse">
                    <img src="../images/dr1.svg" loading="lazy" alt="" />
                  </div>
                  <div class="text-question-f66">
                    Shipping
                    <br />
                  </div>
                </div>
                <div class="icon-wrapper-f66">
                  <img
                    src="../images/arrow_black.svg"
                    loading="lazy"
                    width="14"
                    alt=""
                    class="arrow-black-f66"
                  />
                  <img
                    src="../images/arrow_black.svg"
                    loading="lazy"
                    width="14"
                    style={{ display: "none" }}
                    alt=""
                    class="arrow-blue-f66"
                  />
                </div>
              </div>
              <div class="answer-f66 nobotbro">
                <p class="answer-text-f66">
                  <strong>Delivery: </strong>
                  <span class="date-21">{deliverDateShort}.</span> Order within{" "}
                  <span class="time-order-within">3 hrs 6 mins</span>
                </p>
                <p class="answer-text-f66">
                  <strong>Courier Company: </strong>
                  <span class="ico-txt-bet">
                    <strong>U</strong>
                  </span>
                  <strong> </strong>UNITED STATES POSTAL SERVICE
                </p>
                <p class="answer-text-f66">
                  All orders are shipped with express service and include a
                  tracking number.
                </p>
                <p class="answer-text-f66">
                  We ensure that your package will reach you safely and on time.
                  If there are any problems during transit, we will work to
                  solve the problems and provide the best solutions.
                </p>
              </div>
            </a>
            <a
              data-w-id="af93db0f-648c-042c-1dce-f7169bfce06a"
              href="#"
              class="question-wrapper-f66 w-inline-block"
            >
              <div class="question-f66">
                <div class="lef-faq-div">
                  <div class="ico-div-faq">
                    <img src="../images/rety1.svg" loading="lazy" alt="" />
                  </div>
                  <div class="text-question-f66">
                    Return &amp; Refund Policy
                    <br />
                  </div>
                </div>
                <div class="icon-wrapper-f66">
                  <img
                    src="../images/arrow_black.svg"
                    loading="lazy"
                    width="14"
                    alt=""
                    class="arrow-black-f66"
                  />
                  <img
                    src="../images/arrow_black.svg"
                    loading="lazy"
                    width="14"
                    style={{ display: "none" }}
                    alt=""
                    class="arrow-blue-f66"
                  />
                </div>
              </div>
              <div style={{ height: "0px" }} class="answer-f66">
                <div class="tab-wrapper-div">
                  <div class="tab-sub-div">
                    <div>
                      <img
                        src="../images/pack-return.svg"
                        loading="lazy"
                        alt=""
                        class="secure-dvg"
                      />
                    </div>
                    <div>
                      <div class="sub-text-one">
                        Free returns within 90 days
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="tab-down-div">
                    <div>
                      <img
                        src="../images/tick1.svg"
                        loading="lazy"
                        alt=""
                        class="top-tick-svg"
                      />
                    </div>
                    <div>
                      <div class="dd-tab-text">
                        Any item(s) are eligible for a full refund within 90
                        days of purchase.
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="tab-down-div">
                    <div>
                      <img
                        src="../images/tick1.svg"
                        loading="lazy"
                        alt=""
                        class="top-tick-svg"
                      />
                    </div>
                    <div>
                      <div class="dd-tab-text">
                        Email us at support@getnuzzle.com for quality US-based
                        support.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-wrapper-div">
                  <div class="tab-sub-div">
                    <div>
                      <img
                        src="../images/shield-secure.svg"
                        loading="lazy"
                        alt=""
                        class="secure-dvg"
                      />
                    </div>
                    <div>
                      <div class="sub-text-one">
                        Shop confidently with our secure systems <br />
                      </div>
                    </div>
                  </div>
                  <div class="tab-down-div">
                    <div>
                      <img
                        src="../images/tick1.svg"
                        loading="lazy"
                        alt=""
                        class="top-tick-svg"
                      />
                    </div>
                    <div>
                      <div class="dd-tab-text">
                        Card information is secure and uncompromised
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="tab-down-div">
                    <div>
                      <img
                        src="../images/tick1.svg"
                        loading="lazy"
                        alt=""
                        class="top-tick-svg"
                      />
                    </div>
                    <div>
                      <div class="dd-tab-text">
                        We keep your personal data private, safe, and secure
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </section>
        </div> */}
        <div class="b1-d ds12">
          <div class="cen-div-img">
            <img
              src="../images/can-post-img.svg"
              loading="lazy"
              alt=""
              class="re1-img"
            />
          </div>
          <div class="div-block-680">
            <div>
              <div class="div-block-609">
                <img
                  src="../images/Green-circle.svg"
                  loading="lazy"
                  alt=""
                  class="circle-green"
                />
                <div class="w-embed"></div>
              </div>
            </div>
            <div>
              <div class="ris-tt">READY TO SHIP</div>
            </div>
          </div>
          <div class="sa1-t">
            <span class="date-21">Ships by {deliverDate}</span> USPS order
            within <span class="time-order-within">3 hrs 6 mins</span>
          </div>
        </div>
        <div class="bnb-div">
          <div class="div-block-683"></div>
          <div class="div-block-685">
            <div class="bnb-txt">BUNDLE &amp; SAVE</div>
          </div>
          <div class="div-block-684"></div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
