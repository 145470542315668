import Header from "./header";
import Footer from "./footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div class="section-5">
        <div class="container-12 w-container">
          <img src="../images/logo_v02.png" loading="lazy" width="119" alt="" />
        </div>
      </div>
      <div class="section-6">
        <div class="w-container">
          <h1 class="heading-30">Contact Us</h1>
          <p class="paragraph-22">
            We’re always looking for a better way to do things. And when it
            comes to innovation and technology, we don’t sleep on it. So we can
            make products for you that let you sleep your way.
            <br />
            <br />
            Cozy, comforting and customizable. Because we’re big believers that
            better sleep means a better day. That tonight shapes tomorrow. And
            that to turn your (waking) dreams into a reality, you’re better off
            with a refreshed mind than tired eyes.
            <br />
            <br />
            Everyone sleeps differently. Nuzzle just offers you whatever you
            need to do it best. From naps to all nighters, rest assured.
            Literally.
            <br />
            <br />‍
            <strong>
              What is your return policy? <br />‍
            </strong>
            Our standard return policy lasts 60 calendar days. If 60 days have
            gone by since your purchase, unfortunately, we cannot offer you a
            refund or exchange. To be eligible for a return, your item must be
            unused or in like-new, donatable condition. You do not need the
            original packaging and we are unable to accept returns of stained,
            damaged, or incomplete pillows. To complete your return, we require
            a receipt or proof of purchase.
            <br />
            <br />
            Nuzzle™ is committed to providing the best products and friendliest
            customer service. If you should have any questions about ordering or
            a question about any of our great products, please feel free to
            contact us with the information provided below:
            <br />
            <br />‍<strong>By Email: </strong>
            <a href="mailto:support@getnuzzle.com" target="_blank">
              <strong>support@getnuzzle.com</strong>
              <br />
              <br />
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
