import forge from "node-forge";

const passphrase = "your-secure-passphrase";
const salt = "fixed-salt";
const encryptionKey = forge.pkcs5.pbkdf2(passphrase, salt, 1000, 32);

export function encrypt(data) {
  const iv = forge.random.getBytesSync(16);
  const cipher = forge.cipher.createCipher("AES-CBC", encryptionKey);
  cipher.start({ iv });
  const jsonData = JSON.stringify(data);
  cipher.update(forge.util.createBuffer(jsonData, "utf8"));
  cipher.finish();
  const encrypted = cipher.output.getBytes();
  return forge.util.encode64(iv + encrypted);
}
