import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const onRoute = (route) => {
    navigate("/checkout");
  };

  return (
    <>
      <div class="sticky-nav">
        <a class="button-sm-2 sticky-btn-mb w-button">buy now</a>
        <div class="sticky-nav-wrapper">
          <div class="sticky-nav-item">
            <img
              src="images/hourglass.svg"
              loading="lazy"
              alt=""
              class="gray-icon-2 hourglass"
            />
            <div class="text-block-110">Only 16 Pillows Remaining</div>
          </div>
          <div class="sticky-nav-item">
            <img
              src="images/Green-circle.svg"
              loading="lazy"
              alt=""
              class="circle-green"
            />
            <div class="text-block-111">
              Ships by{" "}
              <span id="date-1" class="date-1">
                Wed, Dec 15th
              </span>
            </div>
            <a class="button-sm-2 sticky-btn w-button" onClick={onRoute}>
              ORDER now
            </a>
          </div>
        </div>
        <div class="w-embed"></div>
      </div>
    </>
  );
};

export default Header;
