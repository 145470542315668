import Header from "./header";
import Footer from "./footer";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div class="section-5">
        <div class="container-12 w-container">
          <img src="images/logo_v02.png" loading="lazy" width="138" alt="" />
        </div>
      </div>
      <div class="section-6">
        <div class="w-container">
          <h1 class="heading-30">About Us</h1>
          <h1 class="heading-31">Who invented the Nuzzle pillows?</h1>
          <div class="div-block-461">
            <div class="div-block-462">
              <h1 class="heading-32">Brand Story</h1>
              <p class="paragraph-22">
                We’re always looking for a better way to do things. And when it
                comes to innovation and technology, we don’t sleep on it. So we
                can make products for you that let you sleep your way. Cozy,
                comforting and customizable. Because we’re big believers that
                better sleep means a better day. That tonight shapes tomorrow.
                And that to turn your (waking) dreams into a reality, you’re
                better off with a refreshed mind than tired eyes.Everyone sleeps
                differently. <strong>nuzzle</strong> just offers you whatever
                you need to do it best. From naps to all-nighters, rest assured.
                Literally.
                <br />
              </p>
            </div>
            <div class="div-block-463">
              <img src="images/up1.jpg" loading="lazy" alt="" />
            </div>
          </div>
          <h1 class="heading-32">Founder Story</h1>
          <p class="paragraph-22">
            The <strong>nuzzle</strong> pillow company was started by Jack
            (current owner and CEO); an experienced entrepreneur from Canada
            looking for a better way to sleep. He tried and tested every pillow
            style and innovation on the market; however, nothing felt
            right.Being the kind of person who doesn&#x27;t settle for less than
            the best, Jack set out to create his own pillow. To achieve this, he
            partnered with one of the world&#x27;s best startup incubators to
            leverage their experience in product design &amp; development,
            manufacturing, and supply chain management to bring the most
            cutting-edge textile technology and the latest sleep-based
            research.The result was the
            <strong>nuzzle</strong> pillow, and we think you’ll agree that it’s
            pretty special. We’re thrilled to be bringing
            <strong>nuzzle</strong> to life for you, and we can’t thank you
            enough for being a part of our journey
            <br />
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
