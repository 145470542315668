import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://nuzzle-services-live.getnuzzle.com/", // Your API base URL
});

// "http://localhost:3002/"

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request config, such as adding headers or logging
    config.headers.Authorization =
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGFpbSI6InVuaXF1ZUFwcFRva2VuIiwiaWF0IjoxNzMyOTEwMTgyLCJleHAiOjMxNzI3NzM1MjU4Mn0.oFmBNa0oGO1XKOnQGqmLKPLyTvMj_61NwsT523LN4yA";
    return config;
  },
  (error) => {
    // Handle request errors
    return error;
    // return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify response data or handle success
    return response;
  },
  async (error) => {
    // Handle response errors
    return error;
    // return Promise.reject(error);
  }
);

export default axiosInstance;
