import Header from "./header";
import Footer from "./footer";

const ReturnPolicy = () => {
  return (
    <>
      <Header />
      <div class="section-5">
        <div class="container-12 w-container">
          <img src="../images/logo_v02.png" loading="lazy" width="138" alt="" />
        </div>
      </div>
      <div class="section-6">
        <div class="w-container">
          <h1 class="heading-30">Return Policy</h1>
          <p class="paragraph-22">
            Please contact customer service for return authorization
            information. Please include your complete name, address, daytime
            telephone number and email address inside the return package when
            returning your item.
            <br />
          </p>
          <div class="div-block-464">
            <a
              href="https://offer.getnuzzle.com/checkout/secure-v06-new"
              class="button-8 w-button"
            >
              BUY NOW
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReturnPolicy;
