export const getDeviceName = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/iphone/.test(userAgent)) return "iPhone";
  if (/ipad/.test(userAgent)) return "iPad";
  if (/macintosh/.test(userAgent) && "ontouchend" in document) return "iPad"; // For macOS iPads
  if (/android/.test(userAgent)) return "Android Device";
  if (/windows phone/.test(userAgent)) return "Windows Phone";
  if (/linux/.test(userAgent) && /mobile/.test(userAgent))
    return "Linux Mobile";
  if (/macintosh|mac os x/.test(userAgent)) return "Mac";
  if (/windows/.test(userAgent)) return "Windows PC";

  return "Unknown Device";
};
