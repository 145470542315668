import { create } from "zustand";
import axiosInstance from "axios-interceptor/axios";

const PurchaseStore = create(() => ({
  managePurchase: async (data) => {
    try {
      const res = await axiosInstance.post("/purchase/manage-purchase", data);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },
  createPaypalOrder: async (data) => {
    try {
      const res = await axiosInstance.post(
        "/purchase/create-paypal-order",
        data
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },
  getPaypalOrderDetails: async (orderID) => {
    try {
      const res = await axiosInstance.get(
        `/purchase/get-paypal-order/${orderID}`
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },
}));

export default PurchaseStore;
